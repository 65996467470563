<template>
  <div>
    <v-card>
      <v-card-title>Listado de centros</v-card-title>
      <v-card-text>
        <botonera 
          :botones="botones" 
          alinear="right"
          @crear="dialog = !dialog"
          @cargar="upload = !upload"
          @eliminar="deleteAll"
        ></botonera>
      </v-card-text>
    </v-card>

    <datatable 
      :cabeceras="centrosListHeaders" 
      :items="centrosListBody" 
      titulo="Listado de Centros de Cultivo" 
      label="Escriba Para buscar Centro de Cultivo" 
      icono="list" color_icono="azul"
      :tachar="true"
      :acciones="['editar','eliminar','mostrar','ocultar']"
      @editar="editCentro($event)"
      @eliminar="deleteCentro($event)"
      @mostrar="mostrar($event)"
      @ocultar="ocultar($event)">
    </datatable>

    <!-- Modal Nuevo Centro -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col cols="6">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="centro.empresa"
                :items="empresas"
                item-text="razon_social"
                item-value="id"
                label="Cliente"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-format-text"
                placeholder="Nombre del centro"
                label="Nombre del centro"
                v-model="centro.nombre"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-numeric"
                :placeholder="latitud"
                label="Latitud"
                v-model="centro.latitud"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                prepend-icon="mdi-numeric"
                :placeholder="longitud"
                label="Longitud"
                v-model="centro.longitud"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="saveCentro"
              >Grabar</v-btn>
        </v-card-actions>
              
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de centros desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadCentros"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'
  

  export default {
    data: () => ({
      title: 'Nuevo Centro',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      search: null,
      file: null,
      dialog: false,
      upload: false,
      empresas: [],
      centroIndex: -1,
      latitud: `40°28'20,00"`,
      longitud: `3°33'39,00"`,
      centro: {
        id: 0,
        nombre: '',
        latitud: '',
        longitud: '',
        empresa: null,
      },
      centrosListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Empresa',
          align: 'center',
          sortable: true,
          value: 'empresa.razon_social',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nombre',
          align: 'center',
          sortable: true,
          value: 'nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Latitud',
          align: 'center',
          sortable: true,
          value: 'latitud',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Longitud',
          align: 'center',
          sortable: true,
          value: 'longitud',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      centrosListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nuevo Centro'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        {boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadEmpresas();
      this.loadCentros();
    },
    methods:{
      async loadEmpresas(){
        let url = `${this.base_url}empresas`;
        await this.axios.get(url, this.headers_json).then((response)=>{
          this.empresas = response.data;
          this.centro.empresa = this.empresas[0];
        }).catch((error)=>{
          console.log(error);
        });
      },
      async loadCentros(){
        this.mostrarLoading('Cargando listado de centros...')
        let url = `${this.base_url}centros`;
        await this.axios.get(url, this.headers_json).then((response)=>{
          this.centrosListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
          console.log(error);
          this.ocultarLoading()
        });
      },
      async saveCentro(){
        // validation
        this.errors = [];
        if(!this.centro.nombre){
          this.errors.push("Debe indicar nombre del centro");
        }else if(this.centro.nombre.length > 30){
          this.errors.push("Tamaño máximo nombre = 30 caracteres");
        }
        if(!this.centro.latitud){ //  || isNaN(this.centro.latitud)){
          this.errors.push("Debe indicar latitud del centro");
        }
        if(!this.centro.longitud){ // || isNaN(this.centro.longitud)){
          this.errors.push("Debe indicar longitud del centro");
        }
        if(this.centro.empresa == null){
          this.errors.push("Debe seleccionar una empresa");
        }
        if(!this.esCoordenadaValida(this.centro.latitud)){
          this.errors.push("No es una latitud válida");
        }
        if(!this.esCoordenadaValida(this.centro.longitud)){
          this.errors.push("No es una longitud válida");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}centros`;
        let centroData = {
          'empresas_id': this.centro.empresa.id,
          'nombre': this.centro.nombre,
          'latitud': this.centro.latitud,
          'longitud': this.centro.longitud,
        };
        if(this.centro.id > 0){
          url = url + "/" + this.centro.id;
          await this.axios.put(url, centroData, this.headers_json).then((response)=>{
            let index = this.centrosListBody.map(centro => {
              return centro.id;
            }).indexOf(this.centro.id);
            this.centrosListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            console.log(error);
            this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, centroData, this.headers_json).then((response)=>{
            this.centrosListBody.push(response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
            this.errors = error.response.data;
            this.validation_message = this.errors.join();
            this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      esCoordenadaValida(str){
        return /^([0-8]?[0-9]|90)°([0-5]?[0-9])'([0-5]?[0-9](,[0-9])")?/.test(str);
      },
      editCentro(item) {
        this.centroIndex = this.centrosListBody.indexOf(item);
        this.centro = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Centro";
      },
      async deleteCentro(item) {
        this.$confirm('Desea borrar este centro?').then(res => {
          if(res){
            let url = `${this.base_url}centros/${item.id}`;
            this.axios.delete(url, this.headers_json).then((response)=>{
              console.log(response);
              const index = this.centrosListBody.indexOf(item);
              this.centrosListBody.splice(index, 1);
              this.showSnackBar(false);
            }).catch((error)=>{
                this.validation_message = error;
                this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nuevo Centro";
      },
      cleanForm(){
        this.centro.id = 0;
        this.centro.nombre = "";
        this.centro.latitud = "";
        this.centro.longitud = "";
        this.centro.empresa = null;
        this.dialog = false;
      },
      async uploadCentros(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/centros`;
        let formData = new FormData();
        formData.append("excel", this.file);
        let response = null;
        try{
          response = await this.axios.post(url, formData, this.headers);
          this.file = null;
          this.upload = false;
          this.loadCentros();
          this.showSnackBar(false);
        }catch(error){
          response = error.response;
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      async deleteAll(){
        this.$confirm('Desea borrar todos los datos?').then(res => {
          if(res){
            let url = `${this.base_url}truncar/centros`;
            this.axios.get(url, this.headers_json).then((response)=>{
              console.log(response);
              this.loadCentros();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async ocultar(item) {
        this.$confirm('Desea ocultar este centro?').then(res => {
          if(res){
            let url = `${this.base_url}centros/ocultar/${item.id}`;
            this.axios.get(url, this.headers_json).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadCentros();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async mostrar(item) {
        this.$confirm('Desea mostrar este centro?').then(res => {
          if(res){
            let url = `${this.base_url}centros/mostrar/${item.id}`;
            this.axios.get(url, this.headers_json).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadCentros();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers','headers_json']),
      
    },
    components:{
      //'datatable':DataTable
    }
  }
</script>